exports.components = {
  "component---src-pages-about-carbon-reduction-plan-index-js": () => import("./../../../src/pages/about/carbon-reduction-plan/index.js" /* webpackChunkName: "component---src-pages-about-carbon-reduction-plan-index-js" */),
  "component---src-pages-about-corporate-governance-statement-index-js": () => import("./../../../src/pages/about/corporate-governance-statement/index.js" /* webpackChunkName: "component---src-pages-about-corporate-governance-statement-index-js" */),
  "component---src-pages-about-road-to-starling-js": () => import("./../../../src/pages/about/road-to-starling.js" /* webpackChunkName: "component---src-pages-about-road-to-starling-js" */),
  "component---src-pages-app-signup-index-js": () => import("./../../../src/pages/app/signup/index.js" /* webpackChunkName: "component---src-pages-app-signup-index-js" */),
  "component---src-pages-apply-index-js": () => import("./../../../src/pages/apply/index.js" /* webpackChunkName: "component---src-pages-apply-index-js" */),
  "component---src-pages-campaign-makemoneyequal-closing-the-gender-play-gap-index-js": () => import("./../../../src/pages/campaign/makemoneyequal/closing-the-gender-play-gap/index.js" /* webpackChunkName: "component---src-pages-campaign-makemoneyequal-closing-the-gender-play-gap-index-js" */),
  "component---src-pages-demoapp-shared-access-invite-index-js": () => import("./../../../src/pages/demoapp/shared-access-invite/index.js" /* webpackChunkName: "component---src-pages-demoapp-shared-access-invite-index-js" */),
  "component---src-pages-demoapp-signup-index-js": () => import("./../../../src/pages/demoapp/signup/index.js" /* webpackChunkName: "component---src-pages-demoapp-signup-index-js" */),
  "component---src-pages-draft-animations-js": () => import("./../../../src/pages/__draft/animations.js" /* webpackChunkName: "component---src-pages-draft-animations-js" */),
  "component---src-pages-draft-mobile-banking-app-demo-index-js": () => import("./../../../src/pages/__draft/mobile-banking-app-demo/index.js" /* webpackChunkName: "component---src-pages-draft-mobile-banking-app-demo-index-js" */),
  "component---src-pages-draft-user-registration-email-verification-index-js": () => import("./../../../src/pages/__draft/user-registration/email-verification/index.js" /* webpackChunkName: "component---src-pages-draft-user-registration-email-verification-index-js" */),
  "component---src-pages-email-preferences-index-js": () => import("./../../../src/pages/email-preferences/index.js" /* webpackChunkName: "component---src-pages-email-preferences-index-js" */),
  "component---src-pages-email-verification-index-js": () => import("./../../../src/pages/email-verification/index.js" /* webpackChunkName: "component---src-pages-email-verification-index-js" */),
  "component---src-pages-email-verification-non-customer-index-js": () => import("./../../../src/pages/email-verification/non-customer/index.js" /* webpackChunkName: "component---src-pages-email-verification-non-customer-index-js" */),
  "component---src-pages-faq-video-index-js": () => import("./../../../src/pages/faq/video/index.js" /* webpackChunkName: "component---src-pages-faq-video-index-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-resources-banking-banking-terms-and-definitions-index-js": () => import("./../../../src/pages/resources/banking/banking-terms-and-definitions/index.js" /* webpackChunkName: "component---src-pages-resources-banking-banking-terms-and-definitions-index-js" */),
  "component---src-pages-resources-business-guides-index-js": () => import("./../../../src/pages/resources/business-guides/index.js" /* webpackChunkName: "component---src-pages-resources-business-guides-index-js" */),
  "component---src-templates-mpme-blog-post-js": () => import("./../../../src/templates/MpmeBlogPost.js" /* webpackChunkName: "component---src-templates-mpme-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

