import React, { useEffect, useState } from 'react';

const NAV_BREAK_WIDTH = 1215;

const isNavWide = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth >= NAV_BREAK_WIDTH ? true : false;
  }
};

const defaultState = {
  headerHeight: 0,
  navWide: isNavWide()
};

const ThemeContext = React.createContext(defaultState);

const ThemeProvider = (props) => {
  const { children } = props;
  const [themeParams, setThemeParams] = useState(defaultState);

  useEffect(() => {
    document.addEventListener('scroll', calculateHeightOnScroll);
    return () => {
      document.removeEventListener('scroll', calculateHeightOnScroll);
    };
  }, []);

  const calculateHeightOnScroll = () => {
    const siteHeader = document.getElementsByClassName('site-header')[0]?.clientHeight || 0;
    const blogCategoriesHeader = document.getElementsByClassName('blog-categories-header')[0]?.clientHeight || 0;
    setThemeParams((params) => {
      return { ...params, headerHeight: siteHeader + blogCategoriesHeader };
    });
  };

  return <ThemeContext.Provider value={themeParams}>{children}</ThemeContext.Provider>;
};

export default ThemeContext;

export { ThemeProvider };
