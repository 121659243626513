// List of query string params which are sent as part of a web flow submit, if provided in the session.
export const SESSION_ALLOW_LIST = [
  'r',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_creative',
  'utm_content',
  'site_id',
  'creative_id',
  'irclickid', // Query string param from Impact
  'ttclid', // TikTok
  'gclid', // Google Ads click ID
  'gclsrc', // Google Ads click source
  'cp_0',
  'cp_1',
  'cp_2',
  'cp_3',
  'cp_4',
  'cp_5',
  'cp_6',
  'cp_7'
  // 'cp_8',   Previous page, before going to /apply/
  // 'cp_9'    Google Optimize test IDs
];
