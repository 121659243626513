window.starlingbank = {
  config: {
    localhost: {
      appIntentProtocol: 'starlingbankdemo://',
      emailVerificationUrl: '/registration/api/v1/email-verification/',
      nonCustomerEmailVerificationUrl: '/referrals/referrals-public-api/api/v1/non-customer/email-verification/',
      sharedAccessEmailVerificationUrl: '/userregistration/api/v1/user-registration/email-verification/',
      documentDownloadFormUrl: '/customer-download-document',
      nonCustomerDocumentDownloadFormUrl: '/referrals/referrals-public-api/api/v1/document-share/download/',
      smeBorrowingFormUrl: '/registration/api/v1/business-lending',
      currentJobOpeningsUrl: 'https://cdznhpvxkrbphuwejyc4pvpwmy0uxwhx.lambda-url.eu-west-2.on.aws/',
      currencyCalculatorUrl: '/partner/api/v2/fx/rates',
      overdraftCalculator: 'https://grykowtbdcd6ngwjfpmvkx2vf40ewhif.lambda-url.eu-west-2.on.aws/',
      referralsForm: '/referrals/referrals-public-api/api/v1/referrals',
      referralsFormAccountants: '/referrals/referrals-public-api/api/v1/referrals/accountancy-partners',
      grecaptchaKey: '6LehzM0ZAAAAAIU_IRYcRu956MmVSs4Emox3KG-8',
      sortCodeChecker: 'http://localhost:7850/api/v2/sort-code/',
      loanRepaymentCalculator: '/partner/api/v2/lending/repayment-holidays/calculate/',
      ecbCalculator: '/partner/api/v2/currency/calculate/',
      ecbCurrencies: '/partner/api/v2/currency/ecb-currencies',
      registerDetails: '/referrals/referrals-public-api/api/v1/referrals/marketing',
      onboardingQrCode: 'QrCodeDemo',
      emailPreferences: '/suggestion/api/v1/communication-consent/marketing-email/'
    },
    demo: {
      appIntentProtocol: 'starlingbankdemo://',
      emailVerificationUrl: 'https://demo-registration.possiblefs.com/api/v1/email-verification/',
      nonCustomerEmailVerificationUrl:
        'https://demo-referrals.possiblefs.com/referrals-public-api/api/v1/non-customer/email-verification/',
      sharedAccessEmailVerificationUrl:
        'https://demo-userregistration.possiblefs.com/api/v1/user-registration/email-verification/',
      documentDownloadFormUrl: 'https://customer-download-document.possiblefs.com',
      nonCustomerDocumentDownloadFormUrl:
        'https://demo-referrals.possiblefs.com/referrals-public-api/api/v1/document-share/download/',
      smeBorrowingFormUrl: 'https://demo-registration.possiblefs.com/api/v1/business-lending',
      currentJobOpeningsUrl: 'https://cdznhpvxkrbphuwejyc4pvpwmy0uxwhx.lambda-url.eu-west-2.on.aws/',
      currencyCalculatorUrl: 'https://api-sandbox.starlingbank.com/api/v2/fx/rates',
      overdraftCalculator: 'https://grykowtbdcd6ngwjfpmvkx2vf40ewhif.lambda-url.eu-west-2.on.aws/',
      referralsForm: 'https://demo-referrals.possiblefs.com/referrals-public-api/api/v1/referrals',
      referralsFormAccountants:
        'https://demo-referrals.possiblefs.com/referrals-public-api/api/v1/referrals/accountancy-partners',
      grecaptchaKey: '6LehzM0ZAAAAAIU_IRYcRu956MmVSs4Emox3KG-8',
      sortCodeChecker: 'https://api-sandbox.starlingbank.com/api/v2/sort-code/',
      loanRepaymentCalculator: 'https://api-sandbox.starlingbank.com/api/v2/lending/repayment-holidays/calculate/',
      ecbCalculator: 'https://api-sandbox.starlingbank.com/api/v2/currency/calculate/',
      ecbCurrencies: 'https://api-sandbox.starlingbank.com/api/v2/currency/ecb-currencies',
      registerDetails: 'https://demo-referrals.possiblefs.com/referrals-public-api/api/v1/referrals/marketing',
      onboardingQrCode: 'QrCodeDemo',
      emailPreferences: 'https://demo-suggestion.possiblefs.com/api/v1/communication-consent/marketing-email/'
    },
    staging: {
      appIntentProtocol: 'starlingbank://',
      emailVerificationUrl: 'https://registration.starlingbank.com/api/v1/email-verification/',
      nonCustomerEmailVerificationUrl:
        'https://prod-referrals.possiblefs.com/referrals-public-api/api/v1/non-customer/email-verification/',
      sharedAccessEmailVerificationUrl:
        'https://userregistration.starlingbank.com/api/v1/user-registration/email-verification/',
      documentDownloadFormUrl: 'https://registration.starlingbank.com/api/v1/document-download/',
      nonCustomerDocumentDownloadFormUrl:
        'https://prod-referrals.jn3cx6xo36.net/referrals-public-api/api/v1/document-share/download',
      smeBorrowingFormUrl: 'https://registration.starlingbank.com/api/v1/business-lending',
      currentJobOpeningsUrl: 'https://cdznhpvxkrbphuwejyc4pvpwmy0uxwhx.lambda-url.eu-west-2.on.aws/',
      currencyCalculatorUrl: 'https://api.starlingbank.com/api/v2/fx/rates',
      overdraftCalculator: 'https://grykowtbdcd6ngwjfpmvkx2vf40ewhif.lambda-url.eu-west-2.on.aws/',
      referralsForm: 'https://prod-referrals.jn3cx6xo36.net/referrals-public-api/api/v1/referrals',
      referralsFormAccountants:
        'https://prod-referrals.jn3cx6xo36.net/referrals-public-api/api/v1/referrals/accountancy-partners',
      grecaptchaKey: '6LfqHcwZAAAAAP7QbbvzxeSqrCkc-u0UMDnQgWOa',
      sortCodeChecker: 'https://api.starlingbank.com/api/v2/sort-code/',
      loanRepaymentCalculator: 'https://api.starlingbank.com/api/v2/lending/repayment-holidays/calculate/',
      ecbCalculator: 'https://api.starlingbank.com/api/v2/currency/calculate/',
      ecbCurrencies: 'https://api.starlingbank.com/api/v2/currency/ecb-currencies',
      registerDetails: 'https://prod-referrals.jn3cx6xo36.net/referrals-public-api/api/v1/referrals/marketing',
      onboardingQrCode: 'QrCodeProd',
      emailPreferences: 'https://prod-suggestion.jn3cx6xo36.net/api/v1/communication-consent/marketing-email/'
    },
    www: {
      appIntentProtocol: 'starlingbank://',
      emailVerificationUrl: 'https://registration.starlingbank.com/api/v1/email-verification/',
      nonCustomerEmailVerificationUrl:
        'https://prod-referrals.possiblefs.com/referrals-public-api/api/v1/non-customer/email-verification/',
      sharedAccessEmailVerificationUrl:
        'https://userregistration.starlingbank.com/api/v1/user-registration/email-verification/',
      documentDownloadFormUrl: 'https://customer-download-document.jn3cx6xo36.net',
      nonCustomerDocumentDownloadFormUrl:
        'https://prod-referrals.jn3cx6xo36.net/referrals-public-api/api/v1/document-share/download',
      smeBorrowingFormUrl: 'https://registration.starlingbank.com/api/v1/business-lending',
      currentJobOpeningsUrl: 'https://cdznhpvxkrbphuwejyc4pvpwmy0uxwhx.lambda-url.eu-west-2.on.aws/',
      currencyCalculatorUrl: 'https://api.starlingbank.com/api/v2/fx/rates',
      overdraftCalculator: 'https://grykowtbdcd6ngwjfpmvkx2vf40ewhif.lambda-url.eu-west-2.on.aws/',
      referralsForm: 'https://prod-referrals.jn3cx6xo36.net/referrals-public-api/api/v1/referrals',
      referralsFormAccountants:
        'https://prod-referrals.jn3cx6xo36.net/referrals-public-api/api/v1/referrals/accountancy-partners',
      grecaptchaKey: '6LfqHcwZAAAAAP7QbbvzxeSqrCkc-u0UMDnQgWOa',
      sortCodeChecker: 'https://api.starlingbank.com/api/v2/sort-code/',
      loanRepaymentCalculator: 'https://api.starlingbank.com/api/v2/lending/repayment-holidays/calculate/',
      ecbCalculator: 'https://api.starlingbank.com/api/v2/currency/calculate/',
      ecbCurrencies: 'https://api.starlingbank.com/api/v2/currency/ecb-currencies',
      registerDetails: 'https://prod-referrals.jn3cx6xo36.net/referrals-public-api/api/v1/referrals/marketing',
      onboardingQrCode: 'QrCodeProd',
      emailPreferences: 'https://prod-suggestion.jn3cx6xo36.net/api/v1/communication-consent/marketing-email/'
    }
  }
};

window.starlingbank.config.starlingbank = window.starlingbank.config.www;

window.starlingbank.getConfig = function (item) {
  if (!item) {
    return;
  }

  var u = new URL(window.location.href);
  var hostname = u.hostname.toLowerCase();

  // e.g. starlingbank or www or demo or staging
  var subdomain = hostname.substring(0, hostname.indexOf('.'));

  // e.g. localhost
  subdomain = subdomain.length ? subdomain : hostname;

  var config = window.starlingbank.config[subdomain] || window.starlingbank.config.www;

  var val = config[item] || window.starlingbank.config.www[item];

  return val;
};
