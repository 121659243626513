/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from './src/context/ThemeContext';
import { SESSION_ALLOW_LIST } from '@starlingbank/shared';
import './src/browserConfig';

const onboardingQueryStringToParams = (queryString) => {
  return queryString
    .slice(1)
    .split('&')
    .reduce((prev, curr) => {
      const [k, v] = curr.split('=');
      if (!SESSION_ALLOW_LIST.includes(k)) return prev;
      return { ...prev, [k]: v };
    }, {});
};

export const onClientEntry = () => {
  // Used for the web flow, onboarding form
  if (window.sessionStorage.setItem) {
    // Always update the params if they're on the allow-list
    if (window.location.search) {
      const attributionParams = onboardingQueryStringToParams(window.location.search);
      if (Object.keys(attributionParams).length) {
        const savedParams = JSON.parse(window.sessionStorage.getItem('attributionParams') || `{}`);
        window.sessionStorage.setItem('attributionParams', JSON.stringify({ ...savedParams, ...attributionParams }));
      }
    }

    // The referrer is also saved, this can be found in gatsby-ssr.js

    // Where there isn't a landing page stored, save the initial page (whatever it is), also save/update it if the url contains a utm_campaign
    if (!window.sessionStorage.getItem('onboardingLandingPage') || document.location.href.includes('utm_campaign')) {
      let landingPageUrl = window.location.origin + window.location.pathname;
      if (window.location.search) {
        const params = onboardingQueryStringToParams(window.location.search);
        if (Object.keys(params).length) {
          const paramString = new URLSearchParams(params).toString();
          landingPageUrl += `?${paramString}`;
        }
      }
      window.sessionStorage.setItem('onboardingLandingPage', landingPageUrl);
    }
  }
};

export const onInitialClientRender = () =>
  console.info("Hey! Looking under the hood? We're always on the lookout for tinkerers; why not get in touch?");

export const wrapRootElement = ({ element }) => (
  <HelmetProvider>
    <ThemeProvider>{element}</ThemeProvider>
  </HelmetProvider>
);

// Forked from gatsby-plugin-react-helmet-canonical-urls
export const wrapPageElement = ({ element, props: { location } }) => {
  const siteUrl = 'https://www.starlingbank.com';
  let pathname = location.pathname || '/';
  let myUrl = `${siteUrl}${pathname}`;
  return (
    <>
      <Helmet
        link={[
          {
            rel: 'canonical',
            key: myUrl,
            href: myUrl
          }
        ]}
      />
      {element}
    </>
  );
};
